(function ($) {
  Drupal.behaviors.socialLogin = {
    attached: false,
    nodes: {},

    _getDOMNodes: function (context) {
      this.nodes.socialLoginContainer = $(context).find('.js-social-login__container');
      this.nodes.triggersContainer = $(context).find('.js-legal-block');
      this.nodes.emailPromoTrigger = this.nodes.triggersContainer.find(
        '.js-social-login__email_promotions--trigger'
      );
      this.nodes.socialTermsAgreementTrigger = this.nodes.triggersContainer.find(
        '.js-social-login__social_login_terms_agreement--trigger'
      );
      this.nodes.socialTermsAgreementContainer = this.nodes.socialTermsAgreementTrigger.parent(
        '.legal-container__acceptance-block'
      );
      this.nodes.emailPromoInputs = this.nodes.socialLoginContainer.find(
        'input[name=PC_EMAIL_PROMOTIONS]'
      );
      this.nodes.termsInputs = this.nodes.socialLoginContainer.find(
        'input[name=ACCEPTED_PRIVACY_POLICY], input[name=SOCIAL_LOGIN_TERMS_AGREEMENT]'
      );
    },

    attachTriggers: function () {
      var self = this;

      self.nodes.emailPromoTrigger.on('change', function () {
        if ($(this).prop('checked')) {
          self.nodes.emailPromoInputs.each(function () {
            $(this).val(1);
          });
        } else {
          self.nodes.emailPromoInputs.each(function () {
            $(this).val(0);
          });
        }
      });
      if (self.nodes.socialTermsAgreementTrigger.length > 0) {
        var socialLoginBlocker = self.nodes.socialLoginContainer.find(
          '.social-login-container__social-network-blocker'
        );

        if (self.nodes.socialTermsAgreementTrigger.prop('checked') === true) {
          socialLoginBlocker.addClass('social-login-container__social-network-blocker_hidden');
        } else {
          socialLoginBlocker.removeClass('social-login-container__social-network-blocker_hidden');
        }
        self.nodes.socialTermsAgreementTrigger.on('change', function () {
          if ($(this).prop('checked')) {
            self.nodes.termsInputs.each(function () {
              $(this).val(1);
            });
            socialLoginBlocker.addClass('social-login-container__social-network-blocker_hidden');
          } else {
            self.nodes.termsInputs.each(function () {
              $(this).val(0);
            });
            socialLoginBlocker.removeClass('social-login-container__social-network-blocker_hidden');
          }
        });
      }
      if (self.nodes.socialTermsAgreementContainer.length > 0) {
        self.nodes.socialLoginContainer
          .find('.social-login-container__social-network-blocker')
          .on('click', function () {
            self.nodes.socialTermsAgreementContainer.addClass(
              'legal-container__acceptance-block_error'
            );
            $('html, body').animate(
              {
                scrollTop:
                  parseInt(self.nodes.triggersContainer.offset().top) -
                  parseInt(window.innerHeight) / 3 +
                  'px'
              },
              500
            );
          });
      }
    },

    init: function (context) {
      this._getDOMNodes(context);
      this.attachTriggers();
    },

    attach: function (context) {
      if (!!this.attached) {
        return;
      }

      this.init(context);
      this.attached = true;
      this.nodes.socialLoginContainer.addClass('attached');
    }
  };
})(jQuery);
